//okcom original
$(function(){
   var mySession = function(){
      if(sessionStorage.getItem('access')){
         //2回目以降のアクセスの処理
         $(window).on('load',function(){   
            $('#wrapper').fadeIn(1200);
         })
      } else{
         //初回アクセスの処理
         $(window).on('load',function(){   
            $("#splash").css("display", "block");
            $('#wrapper').fadeIn(1200);
            $("#splash").delay(2200).fadeOut('slow');//ローディング画面を2.2秒（1500ms）待機してからslowフェードアウト
            $("#splash_logo").delay(2000).fadeOut('slow');//ロゴを2.0秒（1200ms）待機してからslowフェードアウト
         });

         sessionStorage.setItem('access', 'true'); // sessionStorageにデータを保存

      }
   }
   mySession();
});
      
      
      
